import {
  VerifyUser,
  SuperAffiliateConfiguration,
  GetAffiliateUserconfigurationdetails,
  AffiliateEmailSend,
  UpdateCountry,
  EditCurrency,
  CreateUpdatePaymentMethod,
  CreateUpdateDeliveryMethod,
  CreateUpdateEventCategoryAsync,
  DeleteEventCategoryAsync,
  CreateUpdateEventGenresAsync,
  DeleteEventGenressAsync,
  CreateUpdateVenueAsync,
  DeleteVenueAsync,
  OrderAttention,
  OrderEditDetails,
  ExportOrdersCSV,
  DownloadTicket,
  PaymentRefund,
  TransactionAuditTrail,
  ResendTicket,
  GetTransactionsummary,
  GetAllocations,
  ProcessOrder,
  TransactionUpdateStatus,
  ProcessInvoice,
  DownloadInvoice,
  ResendConfirmationEmail,
  SendBarcode,
  ExportTicketALLOCATION,
  SeatAllocationInBulk,
  SendBulkBarcodes,
  SendReceiptInBulk,
  ExportWaitListCSV,
  TimeOutTranscationStatusCheck,
  SetPromoterCommissionOfTheEvent,
  ActiveDeactivePromoterAsync,
  ApproveRejectPromoterRequestAsync,
  SendNotification,
  CreateNotificationList,
  FilterList,
  DownloadEmailNotificationLog,
  ExistingPromotersEntryForNewEvent,
  UpdateInvoiceBankAccount,
  AddAuditTrail,
  InviteToPromoteTheEventAsync,
  ExportUserCSV,
  GetUserNotificationFrequencyTypes,
  SalesEmailNotificationAdminAction,
  UpdateSeat,
  Getseats,
  UploadSeats,
  ExportSeatCSV,
  DeleteEventProduct,
  SaveEventBasicDetails,
  addEventlocation,
  AddEventDate,
  AddEventGeneral,
  UniversalSettingForEmailNotification,
  UpdateEventSettings,
  AddEventTickets,
  AddEventSummary,
  UpdateEventSettingsSuperAffliate,
  UnallocateTicket,
  BulkUpdateSeat,
  ResendAllocationEmail,
  AddEventToTXIS,
  UpdateUserProfile,
  ChangePassword,
  AddBank,
  DeleteUserBank,
  ActivateUserAccount,
  DeleteUserNotification,
  InitiateEmailVerification,
  DeleteEmailNotificationList,
  UserRegistration,
  GetFurpBankProviders,
  FurpGetBeneficiaryRequirement,
  FurpGetBank,
  AddEventToEventBrite,
  UpdateSiteMaintenance,
  UpdateEnquiry,
  AddEnquiry,
  DeteleEventDressTerm,
  UserDelete,
  RegenerateInvoice,
  ReplyEnquiry,
  GetAxsEvents,
  PublishEventExternally,
  TransferTickets,
  GetTicketToTransfer,
  GetGiganticEvents,
  TicketToRecellCancel,
  GetImportedEventDetails,
  CreateImportedEvent,
  UploadImageToS3,
  ExportRedirectedUsersCSV,
  LockTickets,
  UserRegistrationForTicket,
  SubmitTransaction,
  GetAvailableSeats,
  ConfirmChangeSeats
} from './manager';
import { setToken } from './instance';

export default {
  VerifyUser,
  setToken,
  SuperAffiliateConfiguration,
  GetAffiliateUserconfigurationdetails,
  AffiliateEmailSend,
  UpdateCountry,
  EditCurrency,
  CreateUpdatePaymentMethod,
  CreateUpdateDeliveryMethod,
  CreateUpdateEventCategoryAsync,
  DeleteEventCategoryAsync,
  CreateUpdateEventGenresAsync,
  DeleteEventGenressAsync,
  CreateUpdateVenueAsync,
  DeleteVenueAsync,
  OrderAttention,
  OrderEditDetails,
  ExportOrdersCSV,
  DownloadTicket,
  PaymentRefund,
  TransactionAuditTrail,
  ResendTicket,
  GetTransactionsummary,
  GetAllocations,
  ProcessOrder,
  TransactionUpdateStatus,
  ProcessInvoice,
  DownloadInvoice,
  ResendConfirmationEmail,
  SendBarcode,
  ExportTicketALLOCATION,
  SeatAllocationInBulk,
  SendBulkBarcodes,
  SendReceiptInBulk,
  ExportWaitListCSV,
  TimeOutTranscationStatusCheck,
  SetPromoterCommissionOfTheEvent,
  ActiveDeactivePromoterAsync,
  ApproveRejectPromoterRequestAsync,
  SendNotification,
  CreateNotificationList,
  FilterList,
  DownloadEmailNotificationLog,
  ExistingPromotersEntryForNewEvent,
  UpdateInvoiceBankAccount,
  AddAuditTrail,
  InviteToPromoteTheEventAsync,
  ExportUserCSV,
  GetUserNotificationFrequencyTypes,
  SalesEmailNotificationAdminAction,
  UpdateSeat,
  Getseats,
  UploadSeats,
  ExportSeatCSV,
  DeleteEventProduct,
  SaveEventBasicDetails,
  addEventlocation,
  AddEventDate,
  AddEventGeneral,
  UniversalSettingForEmailNotification,
  UpdateEventSettings,
  AddEventTickets,
  AddEventSummary,
  UpdateEventSettingsSuperAffliate,
  UnallocateTicket,
  BulkUpdateSeat,
  ResendAllocationEmail,
  AddEventToTXIS,
  UpdateUserProfile,
  ChangePassword,
  AddBank,
  DeleteUserBank,
  ActivateUserAccount,
  DeleteUserNotification,
  InitiateEmailVerification,
  DeleteEmailNotificationList,
  UserRegistration,
  GetFurpBankProviders,
  FurpGetBeneficiaryRequirement,
  FurpGetBank,
  AddEventToEventBrite,
  UpdateSiteMaintenance,
  UpdateEnquiry,
  AddEnquiry,
  DeteleEventDressTerm,
  UserDelete,
  RegenerateInvoice,
  ReplyEnquiry,
  GetAxsEvents,
  PublishEventExternally,
  TransferTickets,
  GetTicketToTransfer,
  GetGiganticEvents,
  TicketToRecellCancel,
  GetImportedEventDetails,
  CreateImportedEvent,
  UploadImageToS3,
  ExportRedirectedUsersCSV,
  LockTickets,
  UserRegistrationForTicket,
  SubmitTransaction,
  GetAvailableSeats,
  ConfirmChangeSeats
};
