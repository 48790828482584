import jwt_decode from 'jwt-decode';
import { makeVar } from '@apollo/client';
import apolloClient from '../libs/apollo-client';
import queryClient from '../libs/react-query-client';
import clientStorage from '../libs/client-storage';
import {
  TS_ADMIN_AUTH_USER,
  TS_ADMIN_AUTH_TOKEN,
  TS_ADMIN_AUTH_TOKEN_REST_API,
} from '../constants';

// get initial values from the client storage (local or session)

const token = clientStorage.getItem(TS_ADMIN_AUTH_TOKEN);
const user = JSON.parse(clientStorage.getItem(TS_ADMIN_AUTH_USER));

export const isAuthenticatedVar = makeVar(!!token);

export const authenticatedUserVar = makeVar(user);

export const tokenVar = makeVar(token);

export function parseJwt(token) {
  return jwt_decode(token);
}

export const handleLogout = () => {
  setAuthenticatedUser({});
  setIsAuthenticated(false);
  setIsRestApiAuthenticated(false);
  apolloClient.clearStore();
  queryClient.clear();
  localStorage.clear();
};

export const setIsAuthenticated = (token) => {
  if (token) {
    clientStorage.setItem(TS_ADMIN_AUTH_TOKEN, token);
    isAuthenticatedVar(true);
  } else {
    clientStorage.removeItem(TS_ADMIN_AUTH_TOKEN);
    isAuthenticatedVar(false);
  }
};

export const setAuthenticatedUser = (user) => {
  const isUserExist = Object.keys(user).length > 0;

  if (isUserExist) {
    clientStorage.setItem(TS_ADMIN_AUTH_USER, JSON.stringify(user));
    authenticatedUserVar(user);
  } else {
    clientStorage.removeItem(TS_ADMIN_AUTH_USER);
    authenticatedUserVar();
  }
};

export const setIsRestApiAuthenticated = (token) => {
  if (token) {
    clientStorage.setItem(TS_ADMIN_AUTH_TOKEN_REST_API, token);
  } else {
    clientStorage.removeItem(TS_ADMIN_AUTH_TOKEN_REST_API);
  }
};

