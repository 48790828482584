import { toaster } from 'evergreen-ui';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import {
  ApolloClient,
  InMemoryCache,
  from,
  createHttpLink,
} from '@apollo/client';

import { BugsnagNotifier } from './bugsnag-notifier';

import clientStorage from './client-storage';
import { handleLogout } from '../helpers/auth';
import { TS_ADMIN_AUTH_TOKEN_REST_API } from '../constants';
import { encryptData } from '../helpers';

// uses default options for retry link
const retryLink = new RetryLink();

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT_V2,
});

const authLink = setContext((_, { headers }) => {
  const restApiToken = clientStorage.getItem(TS_ADMIN_AUTH_TOKEN_REST_API);

  return {
    headers: {
      ...headers,
      ...(restApiToken ? { authorization: `Bearer ${restApiToken}` } : {}),
      key: encryptData(
        'jq0735Xnd+gkQuLtnescmAK9wt4LjoEO3IAUih1dkdM+y7Y/yITDMR+8s0SuOkcg'
      ),
    },
  };
});

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError) {
    BugsnagNotifier.notify(networkError);
  }

  if (graphQLErrors) {
    graphQLErrors.forEach((graphQLError) => {
      const { path, locations, message } = graphQLError;

      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );

      if (message === 'Could not verify JWT: JWTExpired') {
        handleLogout();
        toaster.notify('Your session has expired. Please login again.', {
          id: 'session_expired',
        });
      } else {
        BugsnagNotifier.notify(graphQLError);
      }
    });
  }
});

const apolloClient = new ApolloClient({
  link: from([errorLink, retryLink, authLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {},
      },
    },
  }),
});

export default apolloClient;
